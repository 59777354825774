/* You can add global styles to this file, and also import other style files */

@import "src/app/common/styles/mixin.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@import "assets/css/app.css";
@import 'src/app/common/styles/mixin';


:root {
  --primary-color: #5FB325;
  --primary-moderate-color: #70D02C;
  --primary-light-color: #EFF7E9;
  --secondary-color: #5BBD75;
  --accent-color: #FAD02C;
  --neutral-dark-color: #333333;
  --neutral-dark-75-color: #5b5b5b;
  --neutral-dark-50-color: #818181;
  --neutral-dark-25-color: #bbbbbb;
  --neutral-dark-10-color: #d4d4d4;
  --neutral-dark-05-color: #e7e7e7;
  --neutral-light-color: #F5F5F5;
}


.password-show-icon {
  cursor: pointer;
  position: absolute;
  font-size: 1.25rem;
  color: var(--primary-color);
  top: 1rem;
  right: 1rem;
}


.input-field-layout {
  width: calc(50% - 1rem);
  @include res-mobile-styles;
  @include res-desktop-styles {
    width: calc(100% - 1rem);
  }

}

.full-width {
  width: calc(100%);
}

